<template>
    <div class="unOuter mainOuter">
        <div class="container_un">
            <div class="subWhite animated fadeInLeft ani_delay05">
                <div class="sub_bg_bot"></div>
                <div class="subTitBox">
                    <p id="t_page_title" class="subTitle">{{$t('deposit.title')}}</p>
                </div>
                <div class="bTable">
                  <div id="wallet-balances--block" style="margin-bottom: 80px; ">
                    <ul>
                      <li v-for="balance in balances">
                        <p class="symbol" :class="balance.coin"><img :src="`/img/symbol/${(balance.coin === 'TRX' ? 'TRON' : balance.coin)}.png`" :alt="balance.coin" onerror="this.src='/img/symbol/default.png'" /></p>
                        <p class="name">{{$t('withdrawal.balance')}}{{balance.coin === 'TRX' ? 'TRON' : balance.coin}}</p>
                        <p class="balance">{{balance.balance}}</p>
                      </li>
                    </ul>
                  </div>

                        <vue-qr :text="address" :size="300" class='mt-4 mb-4 mx-auto d-block'></vue-qr>
                    <h2 style="text-align: center; color: #fff;">{{$t('deposit.address')}}</h2>
                    <p style="text-align: center; color: #fff; font-size: 20px;">
                        {{address}}</p>

                  <div class="bWrap">
                    <button name="submitBtn" class="bOk card_bg5"  v-clipboard:copy="address" v-clipboard:success="onCopy">{{$t('deposit.copy')}}</button>
                  </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VueQr from 'vue-qr'
const CryptoJS = require("crypto-js");
export default {
    components: {VueQr},
    data(){
        return{
            address:'',

          balances: [],
        }
    },

    beforeMount() {
      this.PreloadWalletBalance()
    },

    mounted(){
        this.GetWalletInfo()
    },
    methods:{
      PreloadWalletBalance() {
        this.$axios.post('/member/coin/GetActiveCoinListForHistory', {}).then(
            res => {
              if (res.status !== 200) {
                return
              }

              if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )

                return
              }

              const e_body = res.data.body;
              const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res)

              let balances = []
              for (const row of body.lists) {
                balances = balances.concat({
                  coin: row,
                  balance: 0
                })
              }

              this.balances = balances
            }
        )
      },

        GetWalletInfo(){

            this.$axios.post('/member/coin/GetWalletInfo', {}).then(
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.address = body.wallet.address;
                            this.balances = body.wallet.balances

                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/sign/login";
                                }
                            )
                        }
                    }
                }
            )
        },
        onCopy(e){
            this.$alert(`${this.$i18n.t('deposit.copyComplete')} ${e.text}`);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/wallet";
</style>
